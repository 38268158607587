@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .w-47 {
     width: 11.75rem     
  }
  .ml-name {
    margin-left: 0.35rem;
  }
  .ml--1px {
    margin-left: -1px;
  }
  .ml--2px {
    margin-left: -2px;
  }
}
.product_image_modal {
    input[type="radio"] {
        display: none;
    }
    
    input[type="radio"]:checked + label img {
        outline: 6px solid rgb(26, 86, 219);
        outline-offset: -6px;
    }
}
td.receipt {
  display: none;
}
form#search_cond {
  /* display: flex; */
  font-size: 0.5rem;
}
form#search_cond th {
  font-weight: normal;
  font-size: 0.5rem;
  text-align: left;
}
form#search_cond th,
form#search_cond td {
  padding: 5px 15px;
}
form#search_cond td select,
form#search_cond td input,
form#search_cond td .sub_title {
  display: inline-block;
}
form#search_cond td .sub_title {
  padding: 0 10px;;
}
main {
  position: relative;
  margin-bottom: 60px;
}
main .regist_wrapper {
  text-align: right;
  width: 100%;
  padding: 10px 30px 30px;
  background: #FFF;
  border-top: solid 1px #CCC;
  position: fixed;
  bottom: 0;
}